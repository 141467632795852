.suspense {
  width: 100vw;
  height: calc(100vh - 54px);
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 80px;
    height: 80px;
    margin: 0;
  }
}
