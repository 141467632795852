.header {
  height: 54px;
  width: 100vw;
  background-color: #1464f6;
  position: sticky;
  right: 0;
  top: 0;
  z-index: 410;
  padding-left: 90px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  div {
    display: flex;
  }

  .web-chat {
    width: fit-content;
    display: flex;
    color: #347af0;
    align-items: center;
    justify-content: space-between;
    height: 39px;
    border-radius: 3px;
    cursor: pointer;
    padding: 0 20px;
    margin-right: 20px;
    user-select: none;
    color: #fff;

    span {
      font-size: 15px;
      font-weight: 500;
      width: max-content;
    }
    svg {
      margin-right: 10px;
      width: 25px;
      height: 24px;
    }
    &:hover {
      background-color: #0c57e0;
    }
  }

  .logo-link {
    width: 120px;
    height: 20px;

    svg {
      width: 120px;
      height: 20px;
      transition: all 0.3s ease-in-out;
      &.sidebar-open {
        margin-left: 135px;
      }
    }
  }
}
