.jenna-side {
  height: 100vh;
  float: left;
  width: calc(100vw - 650px);
}

.jenna-side--left {
  background: linear-gradient(-45deg, #23a6d5, #23d5ab, #4352fb, #9900cc);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;

  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  div {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 20vw;
    }
  }
}
