.side-bar {
  position: fixed;
  top: 0;
  left: 0;
  padding-top: 0;
  z-index: 999;
  width: 200px;
  height: 100vh;
  transition: all 0.3s ease-in-out;
  background-color: #0d1f3c;
  white-space: nowrap;

  .sidebar-toggle {
    padding: 15px 5px 15px 21px;

    svg {
      transition: all 0.1s ease-in-out;
      transform: scale(1.4);

      &:hover {
        fill: #1464f6;
        cursor: pointer;
      }
    }
  }

  .user-panel {
    margin: 15px 0;
    width: 100%;
    height: fit-content;
    padding: 10px;
    display: flex;
    overflow: hidden;
    justify-content: flex-start;
    align-items: center;
    text-align: left;
    position: relative;
    white-space: normal;

    .user-avatar {
      min-width: 45px;
      cursor: pointer;
    }
    .avatar-loading {
      position: absolute;
      top: 3.5px;
      left: 3.6px;
      border: 4px solid #fff;
      border-radius: 50%;
      width: 58px;
      height: 58px;

      border: 3px solid transparent;
      border-top-color: rgb(142, 204, 255);
      border-bottom-color: rgb(142, 204, 255);
      border-radius: 50%;
      animation: loader-rotate 1s linear infinite;

      @keyframes loader-rotate {
        0% {
          transform: rotate(0);
        }

        100% {
          transform: rotate(360deg);
        }
      }
    }
    .user-info {
      font-size: 14px;
      padding: 5px 5px 5px 15px;
      line-height: 130%;
      font-weight: bold;
      color: #fff;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      height: 40px;
      -webkit-line-clamp: 2; /* number of lines to show */
      -webkit-box-orient: vertical;
      user-select: none;
    }
  }

  .sidebar-nav {
    white-space: nowrap;

    .sidebar-nav-row {
      padding: 9.8px 22px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      color: #c0c0c0;
      user-select: none;
      cursor: pointer;
      position: relative;

      .root-span {
        height: 24px;
      }

      &.sidebar-nav-row-overflow {
        overflow-x: visible;
        overflow-y: visible;

        &.sidebar-nav-row-collapsed {
          &:hover {
            .settings-popup {
              display: flex;
            }
          }
        }
      }

      &:hover {
        background-color: #1e3252;
      }

      .sidebar-nav-icon {
        display: inline-flex;
        margin-right: 20px;
        flex-shrink: 0;
        transition: all 0.4s ease-in-out;

        svg {
          transform: scale(0.8);
        }
      }

      .sidebar-nav-text {
        min-width: 0;
        font-size: 0.9em;
        font-weight: 500;
        text-decoration: none !important;
        overflow: hidden;
      }

      &.active {
        background-color: #1e3252;
        color: #fff;
        box-shadow: 4px 0px 0px inset #1464f6;
      }

      .sidebar-new-candidates {
        text-align: center;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background-color: #1464f6;
        position: absolute;
        right: 12px;
        color: #fff;
        font-size: 13px;

        &.withNumber {
          width: 25px;
          height: 25px;
          right: 14px;
          position: absolute;
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: Inter, sans-serif;
        }
      }
    }
  }

  .sidebar-more-settings {
    white-space: nowrap;
    max-height: 0px;
    overflow: hidden;

    transition: all 0.5s ease-in-out;

    &.anim-open {
      max-height: 260px;
    }

    &.sidebar-open {
      display: none;
    }

    div {
      padding-left: 67px;
      padding-right: 16px;
      width: 100%;
      display: flex;
      text-align: left;
      align-items: center;
      padding-top: 5px;
      padding-bottom: 5px;
      justify-content: flex-start;
      overflow-x: hidden;
      color: #c0c0c0;
      user-select: none;
      cursor: pointer;

      span {
        min-width: 0;
        margin-top: 4px;
        margin-bottom: 4px;
        font-size: 0.83em;
        font-weight: 500;
      }

      &:hover {
        background-color: #1e3252;
      }

      &.active {
        background-color: #1e3252;
        color: #fff;
        box-shadow: 4px 0px 0px inset #1464f6;
      }
    }
  }

  &.collapsed {
    width: 70px;
  }
}

.jenna-drawer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;

  .jenna-backdrop {
    background-color: #02060c63;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 500;
    animation: fadein 0.2s forwards linear;
    @keyframes fadein {
      0% {
        opacity: 0;
      }

      100% {
        opacity: 1;
      }
    }
  }
}
