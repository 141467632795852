@keyframes fade-in {
  from {
    opacity: 0;
    visibility: hidden;
  }
  to {
    opacity: 1;
    visibility: visible;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
    visibility: visible;
  }
  to {
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes fade-in-up {
  from {
    opacity: 0;
    transform: translateY(10em);
    visibility: hidden;
  }
  to {
    opacity: 1;
    transform: none;
    visibility: visible;
  }
}

@keyframes pop-in {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  1% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    transform: none;
  }
}

@keyframes pop-out {
  0% {
    opacity: 1;
    transform: none;
  }
  99% {
    opacity: 0;
  }
  100% {
    opacity: 0;
    transform: scale(0);
  }
}

@keyframes slide-in {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: none;
  }
}

@keyframes slide-out {
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(-100%);
  }
}

@keyframes slide-sideways-r-in {
  0% {
    transform: translateX(1%);
  }
  100% {
    transform: none;
  }
}

@keyframes slide-sideways-r-out {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(1%);
  }
}

@keyframes slide-sideways-l-in {
  0% {
    transform: translateX(-1%);
  }
  100% {
    transform: none;
  }
}

@keyframes slide-sideways-l-out {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-1%);
  }
}
