.settings-popup {
  top: 0;
  left: 70px;
  position: absolute;
  width: 200px;
  background-color: #0d1f3c;
  display: none;
  align-items: flex-start;
  flex-direction: column;
  z-index: 99999;
  padding: 5px 0;

  &:hover {
    display: flex;
  }

  span {
    padding: 5px 35px;
    width: 100%;
    color: rgb(225, 226, 233);
    font-size: 14px;
    text-decoration: none;

    &:hover {
      background-color: #1e3252;
    }
  }
}
