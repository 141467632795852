.jenna-loader {
  width: 40px;
  height: 40px;
  animation-name: spin;
  animation-duration: 1600ms;
  animation-iteration-count: infinite;
  transform-origin: 50% 37%;
  // animation-timing-function: linear;
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}
