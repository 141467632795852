.jenna-button {
  width: fit-content;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  padding: 10px 30px;
  border-radius: 5px;
  transition: 0.1s;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  &:hover {
    opacity: 0.95;
  }

  &.size-large {
    font-size: 16px;
  }
  &.size-medium {
    font-size: 15px;
    height: 39px;
  }
  &.size-small {
    font-size: 14px;
  }

  &.primaryCta.primary {
    background: #347af0;
    color: white;
    padding: unset;
  }
  &.primaryCta.secondary {
    border: 1px solid #347af0;
    color: #347af0;
  }
  &.cancel.primary {
    background: #e72e52;
    color: white;
  }
  &.cancel.secondary {
    border: 1px solid #e72e52;
    color: #e72e52;
  }
  &.inactive.primary {
    background: #72777a;
    color: white;
    text-align: center;
    padding: unset;

    &:hover {
      opacity: unset;
    }
  }
  &.inactive.secondary {
    border: 1px solid #72777a;
    color: #72777a;
  }

  @keyframes rotateBtn {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .loader {
    border-radius: 50%;
    border: 2px solid currentColor;
    border-bottom-color: transparent;
    height: 20px;
    width: 20px;
    background: transparent !important;
    display: inline-block;
    animation: rotateBtn 0.75s 0s linear infinite;
  }
}
