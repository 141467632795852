.jenna-link {
  display: block;
  overflow-y: auto;
  position: fixed;
  overflow-x: hidden;
  z-index: 700;
  top: 0px;
  left: -400px;
  background: #fff;
  height: 100vh;
  width: 434px;
  transition: all 0.3s ease-in-out;

  &.anim-slideIn {
    left: 70px;

    &.sidebar-open {
      left: 200px;
    }
  }

  &.anim-slideOut {
    left: -500px;
  }

  .drawer-wide-circle {
    width: 150px;
    height: 150px;
    border: 1px solid #3778f0;
    margin: 75px auto;
    border-radius: 100%;
    transition: 0.5s;

    .drawer-narrow-circle {
      width: 100px;
      height: 100px;
      border: 1px dashed #3778f0;
      margin-left: 24px;
      margin-top: 24px;
      border-radius: 100%;
      background-image: url("../../../assets/link.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 35px;
    }
  }

  .drawer-jenna-link {
    background-color: #f2f7fa;
    height: 78px;
    width: 100%;
    padding-top: 19px;
    margin-top: 20px;

    .drawer-link-outer {
      margin: 0 auto;
      width: 100%;
      text-align: center;

      &.msg-outer {
        margin-top: 20px;
      }

      span {
        width: 90%;
        display: inline-block !important;
        cursor: pointer;

        .jenna-link-input {
          margin-left: auto;
          margin-right: auto;
          box-shadow: none;
          width: calc(100% - 40px);
          border-radius: 8px 0px 0px 8px;
          background-color: white !important;
          border: solid 1px #ebf2f8;
          height: 40px;
          background: url("../../../assets/linkIcon.svg") no-repeat 3% 50%;
          padding: 1px 0 0 35px;
          font-size: 13px;
          font-weight: bold;
          text-align: left;
          outline: none;
          cursor: pointer;
          color: #3778f0;

          &.msg-input {
            background: url("../../../assets/messenger.svg") no-repeat 3% 50%;
            background-size: 19px;
          }
        }

        .drawer-copy-link-button {
          width: 40px;
          height: 40px;
          border-radius: 0px 8px 8px 0px !important;
          background-color: #c3d6fa;
          border: none !important;
          float: right;
          font-size: 0.85em;
          text-align: center;

          div {
            width: 40px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
              width: 24px;
              height: 24px;
              object-fit: contain;
            }
          }
        }
      }
    }

    .drawer-link-copied {
      margin: auto;
      width: 388px;
      height: 40px;
      background-color: #e1f4d2;
      border-radius: 8px;
      background-image: url("../../../assets/checked-green.svg");
      background-repeat: no-repeat;
      background-position-x: 133px;
      text-align: center;
      background-position-y: 12px;
      color: #67c91e;
      line-height: 2.8;
      font-family: "Roboto";
      font-size: 14px;

      &.msg-copied {
        margin-top: 20px;
      }
    }
  }

  .drawer-copy-title {
    width: 100%;
    height: 30px;
    color: rgb(36, 36, 36);
    font-size: 28px;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    text-align: left;
    margin: 40px 0 22px 30px;
  }

  .how-to-use {
    font-family: "Roboto", sans-serif;
    width: 100%;
    margin: 20px auto;
    font-size: 20px;
    font-weight: 500;
    text-align: left;
    color: rgb(36, 36, 36);
    margin: 20px 0 22px 30px;
  }

  .how-to-use-container {
    width: 100%;
    margin: 0 auto;
    height: 300px;

    .how-to-step {
      width: calc(100% - 60px);
      margin-left: 30px;
      height: 45px;
      margin-bottom: 5px;
      margin-top: 5px;

      .how-to-step-img {
        width: 45px;
        height: 45px;
        border-radius: 100%;
        float: left;
        box-shadow: 0 2px 7px 1px rgba(218, 218, 218, 0.5);

        &.img-1 {
          background: url("../../../assets/step_1.svg") white center no-repeat;
        }
        &.img-2 {
          background: url("../../../assets/step_2.svg") white center no-repeat;
        }
        &.img-3 {
          background: url("../../../assets/step_3.svg") white center no-repeat;
        }

        div {
          float: left;
          width: 13px;
          height: 13px;
          font-size: 9px;
          background-color: #3778f0;
          text-align: center;
          border-radius: 100%;
          font-weight: 500;
          color: white;
          line-height: 1.5;
        }
      }

      .how-to-step-text {
        // width: calc(100% - 60px);
        height: 100%;
        font-family: "Roboto";
        font-size: 14px;
        line-height: 1.57;
        text-align: left;
        color: #3b3b3b;
        padding-left: 60px;

        a {
          cursor: pointer;
          color: #3778f0;
          font-weight: 700;
        }
      }
    }

    .dashed-line {
      height: 45px;
      width: 1px;
      border-left: 1px dashed silver;
      margin-left: 52px;
    }
  }
}
