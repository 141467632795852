.avatar-img {
  width: 45px;
  height: 45px;
  object-fit: cover;
  border-radius: 50%;
  -webkit-user-drag: none;
  outline: none;

  &::selection {
    background-color: transparent;
  }
}

.avatar-initials {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 52px;
  height: 52px;
  border-radius: 50%;
  font-size: 24px;
  font-family: "Roboto";
  font-weight: 300;
  text-align: center;
  line-height: 52px;
  background-color: #eef1f8;
  color: #3779f0;

  &:hover {
    cursor: pointer;
  }
}