.imageUpload-styles {
  width: 380px;
  height: 280px;
  top: 60px;
  left: 70px;
  transform: none;
  animation: modalOpen 0.25s both;

  @keyframes modalOpen {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }

  .top-avatars {
    background-color: rgb(220, 252, 252);
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding-bottom: 16px;
    img {
      margin: 16px 8px 0 8px;
      border-radius: 50%;
      cursor: pointer;
    }
  }

  .bottom-upload {
    text-align: center;
    padding-top: 10px;

    span {
      color: rgb(41, 41, 41);
      font-size: 13.5px;
      font-family: Roboto, sans-serif;
    }

    .upload-button {
      margin: 20px auto 0 auto;
      width: 130px;
      outline: none;
      background: #3678f0;
      color: white;
      border: none;
      font-family: "Poppins", sans-serif;
      font-size: 16px;
      font-weight: 400;
      padding: 10px 30px;
      border-radius: 6px;
      cursor: pointer;
    }
  }
}
