.notification-header {
    margin-bottom: 15px;
}

.message {
    opacity: 0.6;
    margin-bottom: 15px;
}
 
.toast-new-candidate{ 
    padding: 7px;
    padding-top: 10px;
 
    > div:first-child{
        display: none;
    }

    & ~ button {
        position: relative;
        margin-top: 0px;
        margin-right: 0px;

        svg {
          width: 19px !important;
          height: 55px !important;
          * {
            fill: white;
          }
        }
    }

    button {
        position: relative;
        top: 0;
        right: 8px;  
      
        svg {
          width: 19px !important;
          height: 19px !important;
          * {
            fill: white;
          }
        }
    }
}