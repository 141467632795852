@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");

:root {
  --PhoneInputCountryFlag-height: 1.3em !important;
  --PhoneInputCountrySelectArrow-width: 0.4em !important;
  --PhoneInputCountryFlag-borderColor: none !important;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

a {
  text-decoration: none;
}

::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-thumb {
  background: #d3d3d3;
}
::-webkit-scrollbar-track {
  background: #efefef;
}

.jenna-tooltip1 {
  padding: 5px 10px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  letter-spacing: 0.5px;
  font-family: Roboto, sans-serif;
}

.jenna-tooltip2 {
  padding: 8px 12px !important;
  font-size: 11px !important;
  font-weight: 500 !important;
  letter-spacing: 0.5px;
  font-family: Roboto, sans-serif;
}

.jenna-tooltip3 {
  padding: 5px 10px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  letter-spacing: 0.5px;
  font-family: Roboto, sans-serif;
  z-index: 99995;
}

.jenna-tooltip4 {
  padding: 12px !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  font-family: Roboto, sans-serif !important;
}

.Toastify__toast-container {
  z-index: 12000 !important;
}

.Toastify__toast--info,
.Toastify__toast--success,
.Toastify__toast--warning,
.Toastify__toast--error {
  background-color: #2196f3 !important;
  min-height: unset !important;
  height: fit-content;
  flex: 1 1;
  margin-right: 15px;
}

.Toastify__toast--success {
  background-color: #4caf50 !important;
}

.Toastify__toast--warning {
  background-color: #ffc400 !important;
}

.Toastify__toast--error {
  background-color: #ff0000 !important;
}

.Toastify__toast-body {
  color: white;

  svg {
    * {
      fill: white;
    }
  }
}

.Toastify__close-button {
  position: absolute;
  top: 50%;
  right: 8px;
  height: 19px !important;
  transform: translateY(-50%);

  svg {
    width: 19px !important;
    height: 19px !important;
    * {
      fill: white;
    }
  }
}

.custom-select2 div {
  cursor: pointer;
}

body > iframe {
  display: none !important;
}

.root-span {
  display: flex;
  align-items: center;
  height: fit-content;
}
