.jenna-modal-content {
  position: absolute;
  width: 620px;
  height: fit-content;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  background: #fff;
  -webkit-overflow-scrolling: touch;
  outline: none;
  z-index: 10002;
  font-family: "Roboto", sans-serif;

  .modal-title {
    font-size: 24px;
    font-weight: 500;
    padding: 24px;
  }

  .modal-subtitle {
    padding: 0 24px;
    color: #252729;
  }

  .cta {
    position: absolute;
    left: 24px;
    bottom: 24px;

    &.cta-cancel {
      left: 198px;
    }
  }
}

.jenna-modal-overlay {
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(20, 20, 20, 0.75);
}
