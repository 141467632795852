.error-boundary {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  .error-right {
    width: 300px;

    svg {
      transform: rotate(-18deg);
      width: 100%;

      path {
        &:nth-child(1) {
          fill: #1464f6;
        }
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4) {
          fill: #125de7;
        }
      }
    }
  }
}
